import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { colors, fontSizes } from "../structure/theme"
import { Helmet } from "react-helmet"
import { fontFaces } from "../../layouts/default.layout"
import { useTranslation } from "../translation/translate.component"

const Table = styled.table`
  margin: auto;
  width: 100%;
  max-width: 900px;
`

const Title = styled.h1`
  margin: 50px 0;
  font-size: ${fontSizes(`xxx_large`)};
  color: ${colors(`primary`)};
`

const Sentence = styled.div`
  font-size: ${fontSizes(`large`)};
  text-align: center;
  margin-bottom: 100px;
`

const Link = styled.a`
  width: 100%;
  text-align: center;
  display: block;
`

const ImgContainer = styled.div`
  text-align: center;
`

const Img = styled.img`
  width: 150px;
  max-width: 100%;
`

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: ${props => props.theme.fonts.primary.family};
    font-size: ${props => props.theme.fonts.sizes.regular};
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
`

export default function OldBrowser () {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <style>{fontFaces}</style>
      </Helmet>
      <GlobalStyle />
      <div>
        <Table>
          <thead>
            <tr>
              <th colSpan={4}>
                <Title>Oups...</Title>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={4}>
                <Sentence>{t(`common:landing.obsolete_browser`)}</Sentence>
              </td>
            </tr>
            <tr>
              <td>
                <ImgContainer>
                  <Img alt="Chrome logo" src={`${APP_CONFIG.basePath}/images/navigators/chrome.png`} />
                </ImgContainer>
              </td>
              <td>
                <ImgContainer>
                  <Img alt="Firefox logo" src={`${APP_CONFIG.basePath}/images/navigators/firefox.png`} />
                </ImgContainer>
              </td>
              <td>
                <ImgContainer>
                  <Img alt="Opera logo" src={`${APP_CONFIG.basePath}/images/navigators/opera.png`} />
                </ImgContainer>
              </td>
              <td>
                <ImgContainer>
                  <Img alt="Edge logo" src={`${APP_CONFIG.basePath}/images/navigators/edge.png`} />
                </ImgContainer>
              </td>
            </tr>
            <tr>
              <td>
                <Link target="_blank" href="https://www.google.fr/intl/fr/chrome/">
                  Chrome
                </Link>
              </td>
              <td>
                <Link target="_blank" href="https://www.mozilla.org/fr/firefox/new/">
                  Firefox
                </Link>
              </td>
              <td>
                <Link target="_blank" href="https://www.opera.com/fr/download">
                  Opéra
                </Link>
              </td>
              <td>
                <Link target="_blank" href="https://www.microsoft.com/fr-fr/windows/microsoft-edge">
                  Edge
                </Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
